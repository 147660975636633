<template>
  <div id="wrapper">
    SOBRE
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style scoped>
</style>
